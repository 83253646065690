<template>
    <section class="tab-header" :class="'size-' + size">
        <img v-if="icon" class="icon" :class="iconClass" :src="icon" v-b-tooltip :title="title">

        <h3 v-if="noInput">{{title}}</h3>

        <slot name="input">
            <b-input-group v-if="!noInput" class="name-container">
                <slot name="input-prepend"></slot>
                <b-form-input ref="input" @input="changed" type="text" class="input-name" v-bind:value="value" maxlength="50" :style="style" :readonly="readonly" :placeholder="placeholder|tr"/>
                <b-input-group-text v-if="!readonly && !noEditIcon" @click="focus">
                    <i class="fas fa-edit"></i>
                </b-input-group-text>
                <slot name="input-append"></slot>
            </b-input-group>
        </slot>

        <p class="c-white mt-1">
            <slot name="revision">
                <template v-if="item && item.revision > 0 && item.date_edition">
                    <b-badge class="md mr-1" variant="light">
                        v{{item.revision+1}}

                        <e-hint :text="`Last modified on ${datetime(item.date_edition)} by ${item.edited_by}|Dernière modification le ${datetime(item.date_edition)} par ${item.edited_by}`"/>
                    </b-badge>
                </template>
            </slot>
            <slot name="status">
                <template v-if="item">
                    <b-badge class="md mr-1" variant="light" v-if="item.status === `draft`">
                        <i class="fas fa-file-pen mr-1"/> <span v-tr>Draft|Brouillon</span>
                    </b-badge>
                    <b-badge class="md mr-1" variant="light-danger" v-else-if="item.deleted">
                        <i class="fas fa-trash mr-1"/> <span v-tr>Deleted|Supprimé</span>
                    </b-badge>
                    <b-badge class="md mr-1" variant="light-danger" v-else-if="item.status === `ended`">
                        <i class="fas fa-close mr-1"/> <span v-tr>Ended|Terminé</span>
                    </b-badge>
                    <b-badge class="md mr-1" variant="light-danger" v-else-if="item.status === `limit-reached`">
                        <i class="fas fa-battery-full mr-1"/> <span v-tr>Limit Reached|Limite atteinte</span>
                    </b-badge>
                    <b-badge class="md mr-1" variant="light-warning" v-else-if="item.archived">
                        <i class="fas fa-box-archive mr-1"/> <span v-tr>Archived|Archivé</span>
                    </b-badge>
                    <b-badge class="md mr-1" variant="light-success" v-else-if="item.status === `published`">
                        <i class="fas fa-circle-check mr-1"/> <span v-tr>Published|Publié</span>
                    </b-badge>
                </template>
            </slot>
            <slot name="subtitle">{{subtitle|tr}}</slot>
        </p>
    </section>
</template>

<script>
    import {datetime} from "../../vue-components/helpers/vue-filters.js";

    export default {
        name: `tab-header`,
         props: {
             value: [String, Object],
             title: { type: String, default: null },
             subtitle: { type: String, default: null },
             placeholder: { type: String, default: `` },
             icon: { type: String, default: null },
             iconClass: { type: String, default: `` },
             maxlength: { type: Number, default: 50 },
             size: { type: String, default: `md` },
             noInput: { type: Boolean },
             noEditIcon: { type: Boolean },
             readonly: { type: Boolean },
             item: { type: Object }
         },
        data() {
            return {
                datetime: datetime
            }
        },
        computed: {
          style() {
              if (this.isMobileLayout) {
                  return {};
              }
              if (this.value && this.value.length > 35) {
                  return {
                      fontSize: `12pt`
                  }
              } else {
                  return {};
              }
          }
        },
        methods: {
            changed(value) {
                this.$emit(`input`, value);
            },
            focus() {
                this.$refs.input.select();
            }
        }
    }
</script>

<style lang="scss" scoped>
.tab-header {
    position: relative;
    padding-left: 100px;
    margin-bottom: 10px;
    height: 100px;

    .icon {
        width: 100px;
        position: absolute;
        top: 0;
        left: 0;
    }

    .name-container {
        padding-top: 15px;

        .input-name,
        .input-group-text {
            border: 1px solid rgba(255, 255, 255, 0.2);
            background-color: rgba(0, 0, 0, 0.07);

            &:focus,
            &:hover {
                background-color: rgba(0, 0, 0, 0.15);
            }
        }

        .input-name {
            &:not(:last-child),
            &:not([readonly]) {
                border-right: none;
            }
            color: white;
            text-align: left;
            display: inline-block;
            max-width: 500px;
            font-size: 16pt;
            font-weight: 400;
            letter-spacing: 1px;
            margin-left: 10px;
            padding-left: 10px;
        }

        .input-group-text {
            color: rgba(255, 255, 255, 0.8);
            cursor: pointer;
        }
    }

    h3 {
        padding-top: 15px;
        padding-left: 20px;
    }

    p {
        padding-left: 20px;
        opacity: 0.8;
    }

    &.size-sm {
        padding-left: 60px;
        margin-bottom: 10px;
        height: 60px;

        .icon {
            width: 60px;
        }

        .name-container {
            padding-top: 5px;

            .input-name {
                font-size: 10pt;
                font-weight: 500;
            }
        }
    }

    @container(max-width: 600px) {
        padding-left: 60px;
        margin-bottom: 10px;

        .icon {
            width: 60px;
        }

        .name-container {
            padding-top: 5px;

            .input-name {
                font-size: 10pt;
                font-weight: 500;
            }
        }

        p {
            font-size: 9pt;
            padding-left: 10px;
        }
    }
}
</style>
