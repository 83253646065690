<template>
    <section id="e-iframe-page">
        <template v-if="successScreen">
            <div class="success-screen">
                <slot name="success-screen" :close="close" :refresh="refresh" :send-message="sendMessage">
                    <div class="success-screen-card text-center">
                        <div class="sa mx-auto">
                            <div class="sa-success">
                                <div class="sa-success-tip"></div>
                                <div class="sa-success-long"></div>
                                <div class="sa-success-placeholder"></div>
                                <div class="sa-success-fix"></div>
                            </div>
                        </div>
                        <h4 class="title"><slot name="success-title">{{successTitle|tr}}</slot></h4>
                        <p class="description"><slot name="success-description">{{successDescription|tr}}</slot></p>

                        <div class="actions">
                            <b-btn variant="light" size="lg" class="mr-2" v-if="!noCloseOnSuccess" @click="close">{{ closeTitle|tr }}</b-btn>
                            <slot name="success-actions" :close="close" :refresh="refresh" :send-message="sendMessage"></slot>
                        </div>
                    </div>
                </slot>
            </div>
        </template>
        <template v-else>
            <div class="e-iframe-page-header" :class="headerClass">
                <slot name="header"></slot>
            </div>
            <div class="e-iframe-page-body" :class="bodyClass" :style="bodyStyle">
                <slot></slot>
            </div>
            <div class="e-iframe-page-footer" :class="footerClass" v-if="$scopedSlots.footer">
                <b-btn variant="light" class="mr-2" v-if="!noCancel" :disabled="cancelDisabled" @click="close">{{ cancelTitle|tr }}</b-btn>
                <slot name="footer" :close="close" :refresh="refresh" :send-message="sendMessage"></slot>
            </div>
            <div v-if="loading" class="main-loading">
                <div class="lds-ripple">
                    <div></div>
                    <div></div>
                </div>
                <div class="" v-tr>Loading please wait...|Chargement, veuillez patienter...</div>
            </div>
        </template>
    </section>
</template>

<script>

// e-iframe-page #hot-reload-debug
export default {
    name: `e-iframe-page`,
    props: {
        title: {type: String},
        bodyClass: {type: String},
        headerClass: {type: String},
        footerClass: {type: String},
        loading: {type: Boolean},
        bodyWidth: {type: [Number, String], default: null},
        successScreen: {type: Boolean},
        noFooter: {type: Boolean},
        successTitle: {type: String, default: `Saved Successfully|Enregistré`},
        successDescription: {type: String, default: ``},
        noCloseOnSuccess: {type: Boolean},
        closeTitle: {type: String, default: `Close|Fermer`},
        noCancel: {type: Boolean},
        cancelDisabled: {type: Boolean},
        cancelTitle: {type: String, default: `Cancel|Annuler`}
    },
    computed: {
        bodyStyle() {
            let style = {};
            if (this.bodyWidth) {
                style.width = `${this.bodyWidth}px`;
                style.maxWidth = `100%`;
                style.marginLeft = `auto`;
                style.marginRight = `auto`;
            }
            return style;
        }
    },
    methods: {
        sendMessage(messageData) {
            let data = {
                type: `e-iframe-dialog`,
                url: document.location.href,
                iframeId: window.frameElement ? window.frameElement.id : window.name
            };
            for (let key in messageData) {
                data[key] = messageData[key];
            }
            if (window.top) {
                window.top.postMessage(data, `*`);
            }
            if (window.opener) {
                window.opener.postMessage(data, `*`);
            }
        },
        refresh() {
            this.sendMessage({
                action: `refresh`
            })
        },
        saved(item, autoClose = true) {
            this.sendMessage({
                action: `saved`,
                item: item,
                autoClose: autoClose
            })
        },
        created(item, autoClose = true) {
            this.sendMessage({
                action: `created`,
                item: item,
                autoClose: autoClose
            })
        },
        close() {
            this.sendMessage({
                action: `close`
            })
        }
    }
}
</script>

<style lang="scss" scoped>
#e-iframe-page {
    position: relative;
    width: 100%;
    max-height: 100vh;
    max-height: var(--app-height);
    min-height: 100vh;
    min-height: var(--app-height);
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 61px;

    .e-iframe-page-body {
        padding: 15px 15px;
    }

    .e-iframe-page-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: white;
        display: flex;
        padding: 10px 10px 10px 20px;
        border-top: 1px solid #ddd;
        justify-content: flex-end;
        align-items: center;

        .btn {
            padding: 10px 20px;
            border-radius: 5px;
            height: auto !important;
        }
    }
}

.main-loading {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.25);
    pointer-events: all;
    text-align: center;
    padding-top: 20%;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 9pt;

    .lds-ripple {
        display: inline-block;
        position: relative;
        width: 64px;
        height: 64px;
    }

    .lds-ripple div {
        position: absolute;
        border: 4px solid #fff;
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }

    .lds-ripple div:nth-child(2) {
        animation-delay: -0.5s;
    }

    @keyframes lds-ripple {
        0% {
            top: 28px;
            left: 28px;
            width: 0;
            height: 0;
            opacity: 1;
        }
        100% {
            top: -1px;
            left: -1px;
            width: 58px;
            height: 58px;
            opacity: 0;
        }
    }
}

.success-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, white, #efffeb);

    .success-screen-card {
        background: white;
        border-radius: 20px;
        padding: 20px;
        width: 470px;
        box-shadow: 0 20px 40px rgba(black, 0.1);

        .title {
            color: #62a23e;
            text-transform: none;
            position: relative;
            display: block;
            padding: 13px 16px;
            font-size: 27px;
            font-weight: 500;
            margin-top: 0;
            margin-bottom: 13px;
        }
    }

    .sa {
        width: 140px;
        height: 140px;
        padding: 26px;
        background-color: #fff;

        &-success {
            border-radius: 50%;
            border: 4px solid #A5DC86;
            box-sizing: content-box;
            height: 80px;
            padding: 0;
            position: relative;
            background-color: #fff;
            width: 80px;

            &:after, &:before {
                background: #fff;
                content: '';
                height: 120px;
                position: absolute;
                transform: rotate(45deg);
                width: 60px;
            }

            &:before {
                border-radius: 40px 0 0 40px;
                width: 26px;
                height: 80px;
                top: -17px;
                left: 5px;
                transform-origin: 60px 60px;
                transform: rotate(-45deg);
            }

            &:after {
                border-radius: 0 120px 120px 0;
                left: 30px;
                top: -11px;
                transform-origin: 0 60px;
                transform: rotate(-45deg);
                animation: rotatePlaceholder 4.25s ease-in;
            }

            &-placeholder {
                border-radius: 50%;
                border: 4px solid rgba(165, 220, 134, 0.25);
                box-sizing: content-box;
                height: 80px;
                left: -4px;
                position: absolute;
                top: -4px;
                width: 80px;
                z-index: 2;
            }

            &-fix {
                background-color: #fff;
                height: 90px;
                left: 28px;
                position: absolute;
                top: 8px;
                transform: rotate(-45deg);
                width: 5px;
                z-index: 1;
            }

            &-tip, &-long {
                background-color: #A5DC86;
                border-radius: 2px;
                height: 5px;
                position: absolute;
                z-index: 2;
            }

            &-tip {
                left: 14px;
                top: 46px;
                transform: rotate(45deg);
                width: 25px;
                animation: animateSuccessTip .75s;
            }

            &-long {
                right: 8px;
                top: 38px;
                transform: rotate(-45deg);
                width: 47px;
                animation: animateSuccessLong .75s;
            }
        }
    }

    @keyframes animateSuccessTip {
        0%, 54% {
            width: 0;
            left: 1px;
            top: 19px;
        }
        70% {
            width: 50px;
            left: -8px;
            top: 37px;
        }
        84% {
            width: 17px;
            left: 21px;
            top: 48px;
        }
        100% {
            width: 25px;
            left: 14px;
            top: 45px;
        }
    }
    @keyframes animateSuccessLong {
        0%, 65% {
            width: 0;
            right: 46px;
            top: 54px;
        }
        84% {
            width: 55px;
            right: 0;
            top: 35px;
        }
        100% {
            width: 47px;
            right: 8px;
            top: 38px;
        }
    }
    @keyframes rotatePlaceholder {
        0%, 5% {
            transform: rotate(-45deg);
        }
        100%, 12% {
            transform: rotate(-405deg);
        }
    }
}
</style>
