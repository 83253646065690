<template>
    <b-input v-model="internalValue" :id="id" :name="name" :disabled="disabled" :required="required" :form="form"
             :autofocus="autofocus" :size="size" :state="stateComputed" :aria-invalid="ariaInvalid" :readonly="readonly"
             :plaintext="plaintext" :autocomplete="autocomplete" :placeholder="placeholder" :formatter="formatter"
             :trim="trim" :lazy-formatter="lazyFormatter" :number="number" :lazy="lazy" :debounce="debounce"
             :maxlength="maxLengthComputed" ref="input"
             :type="typeComputed" :no-wheel="noWheel" :min="min" :max="max" :step="step" :list="list"
             @keypress="onKeyPressed" @input="onInput" @change="onChange"
             @click="$emit('click', $event)"
             @update="$emit('update', $event)" @blur="$emit('blur', $event)"
             @focusin="$emit('focusin', $event)" @focusout="$emit('focusout', $event)">
    </b-input>
</template>

<script>
import Validation from "../helpers/Validation";

export default {
    name: `e-input`,
    props: {
        id: {type: String},
        name: {type: String},
        disabled: {type: [Boolean, String]},
        required: {type: Boolean, default: false},
        form: {type: String},
        autofocus: {type: Boolean, default: false},
        size: {type: String, default: `md`},
        state: {type: [Boolean, String], default: null},
        value: {type: [String, Number], default: ``},
        ariaInvalid: {type: [String, Boolean], default: false},
        readonly: {type: Boolean, default: false},
        plaintext: {type: Boolean, default: false},
        minlength: {type: [String, Number]},
        maxlength: {type: [String, Number]},
        autocomplete: {type: String, default: `off`},
        placeholder: {type: String, default: ``},
        formatter: {type: Function},
        lazyFormatter: {type: Boolean},
        trim: {type: Boolean, default: true},
        number: {type: Boolean, default: false},
        lazy: {type: Boolean},
        debounce: {type: [Number, String]},
        type: {type: String, default: `text`},
        noWheel: {type: Boolean, default: false},
        min: {type: [String, Number]},
        max: {type: [String, Number]},
        step: {type: [String, Number]},
        list: {type: String},
        debounceTime: {type: Number, default: 500}
    },
    data() {
        return {
            internalValue: null,
            timeout: null
        }
    },
    computed: {
        typeComputed() {
            switch (this.type) {
                case `name`:
                case `phone`:
                case `alphanumerical`:
                case `mac`:
                case `ip`:
                case `postal-code`:
                    return `text`;
                case `number`:
                    return `text`;// otherwise issue with floating numbers
                case `url`:
                    return `url`;
                default:
                    return this.type;
            }
        },
        minLengthComputed() {
            if (this.minlength) {
                return this.minlength;
            }
            switch (this.type) {
                case `alphanumerical`:
                case `name`:
                    return 2;
                case `tel`:
                    return 10;
                case `mac`:
                    return 17;
                case `ip`:
                    return 7;
                case `url`:
                    return 10;
                case `postal-code`:
                    return 5;
                default:
                    return 0;
            }
        },
        maxLengthComputed() {
            if (this.maxlength) {
                return this.maxlength;
            }
            switch (this.type) {
                case `tel`:
                    return 18;
                case `mac`:
                    return 17;
                case `ip`:
                    return 15;
                case `postal-code`:
                    return 8;
                case `url`:
                    return 255;
                default:
                    return 50;
            }
        },
        stateComputed() {
            if (this.state === `disabled`) {
                return null;
            } else if (this.state !== null) {
                return this.state;
            } else {
                if (!this.required && this.isStringEmpty(this.internalValue)) {
                    return null;
                } else if (this.required && this.isStringEmpty(this.internalValue)) {
                    return false;
                }
                if (this.minLengthComputed > 0) {
                    if (this.internalValue.length < this.minLengthComputed) {
                        return false;
                    }
                }
                if (this.maxLengthComputed) {
                    if (this.internalValue.length > this.maxLengthComputed) {
                        return false;
                    }
                }
                switch (this.type) {
                    case `mac`:
                        return Validation.testMacAddress(this.internalValue, this.required);
                    case `ip`:
                        return Validation.testIpAddress(this.internalValue, this.required);
                    case `number`:
                        return Validation.testNumber(this.internalValue, this.min, this.max, this.required);
                    case `name`:
                        return Validation.testName(this.internalValue, this.required);
                    case `alphanumerical`:
                        return Validation.testAlphaNumerical(this.internalValue, this.required);
                    case `email`:
                        return Validation.testEmail(this.internalValue, this.required);
                    case `phone`:
                        return Validation.testPhoneNumber(this.internalValue, this.required);
                    case `url`:
                        return Validation.testURL(this.internalValue, this.required);
                    case `password`:
                        return Validation.testPassword(this.internalValue, this.required);
                    case `postal-code`:
                        return Validation.testPostalCode(this.internalValue, this.required);
                    default:
                        return true;
                }
            }
        }
    },
    mounted() {
        this.internalValue = this.value;
    },
    methods: {
        onInput(value) {
            if (this.type !== `number`)
                this.$emit(`input`, value);
        },
        onChange(value) {
            let newValue = value;
            if (this.type === `number`) {
                newValue = Number(value.replace(/,/g, `.`));
                if (isNaN(newValue) || isStringEmpty(value))
                    newValue = null;
                this.$emit(`input`, newValue);
            }

            this.$emit(`change`, newValue);
            this.internalValue = newValue;
        },
        onKeyPressed(event) {
            this.$emit(`keypress`, event);
            if (event.keyCode === 13) {
                this.$emit(`enter`, event);
            }
        },
        focus() {
            this.$refs.input.focus();
        },
        select() {
            this.$refs.input.select();
        },
        isStringEmpty(value) {
            return value === undefined || value === null || value === ``;
        }
    },
    watch: {
        value() {
            this.internalValue = this.value;

            if (this.timeout)
                clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.$emit(`debounce`, this.value);
            }, this.debounceTime);
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
