class Validation {
    static testRequired(value) {
        return !isStringEmpty(value);
    }

    static testNumber(value, min, max, required = true) {
        if (required && isStringEmpty(value)) {
            return false;
        }
        if (isNaN(parseFloat(value))) {
            return false;
        }

        if (typeof min === `number` && typeof max === `number`) {
            return min <= value && value <= max;
        } else if (typeof min === `number`) {
            return min <= value;
        } else if (typeof max === `number`) {
            return value <= max;
        } else {
            return true;
        }
    }

    static testName(value, required = true) {
        if (required && isStringEmpty(value)) {
            return false;
        }

        if (isStringEmpty(value)) {
            return true;
        }

        return /^[a-zA-ZÀ-ÿ-. ]*$/i.test(value);
    }

    static testAlphaNumerical(value, required = true) {
        if (required && isStringEmpty(value)) {
            return false;
        }

        if (isStringEmpty(value)) {
            return true;
        }

        return /^[0-9a-zA-ZÀ-ÿ-. ]*$/i.test(value);
    }

    static testPhoneNumber(value, required = true) {
        if (required && isStringEmpty(value)) {
            return false;
        }

        if (isStringEmpty(value)) {
            return true;
        }

        // eslint-disable-next-line no-useless-escape
        return /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g.test(value);
    }

    static testPassword(value, required = true) {
        if (required && isStringEmpty(value)) {
            return false;
        }

        if (isStringEmpty(value)) {
            return true;
        }

        return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,32}$/.test(value); // 8 chars, at least 1 uppercase, at least 1 number
    }

    static testEmail(value, required = true) {
        if (required && isStringEmpty(value)) {
            return false;
        }

        if (isStringEmpty(value)) {
            return true;
        }

        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(value);
    }

    static testURL(value, required = true) {
        if (required && isStringEmpty(value)) {
            return false;
        }

        if (isStringEmpty(value)) {
            return true;
        }

        return /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/.test(value);
    }

    static testMacAddress(value, required = true) {
        if (required && isStringEmpty(value)) {
            return false;
        }

        if (isStringEmpty(value)) {
            return true;
        }

        return /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/.test(value);
    }

    static testIpAddress(value, required = true) {
        if (required && isStringEmpty(value)) {
            return false;
        }

        if (isStringEmpty(value)) {
            return true;
        }

        return /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/.test(value);
    }

    static testPostalCode(value, required = true) {
        if (required && isStringEmpty(value)) {
            return false;
        }

        if (isStringEmpty(value)) {
            return true;
        }

        let type = `ca`;
        if (value.length === 5) {
            type = `us`;
        }

        if (type === `us`) {
            const us = new RegExp(`^\\d{5}(-{0,1}\\d{4})?$`);
            if (us.test(value.toString())) {
                return true;
            }
        }

        if (type === `ca`) {
            const ca = new RegExp(/^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i);
            if (ca.test(value.toString())) {
                return true;
            }
        }

        return false;
    }

    static testText(value, required = true) {
        if (required && isStringEmpty(value)) {
            return false;
        }

        if (isStringEmpty(value)) {
            return true;
        }

        return !/(&nbsp;|<([^>]+)>)/ig.test(value);
    }

    static testTranslatedText(value, required = true) {
        const empty = isStringEmpty(value) || (value && (isStringEmpty(value.en) && isStringEmpty(value.fr)));

        if (required && empty) {
            console.log(value);
            return false;
        }

        if (empty) {
            return true;
        }

        return true;
    }
}

export default Validation;
